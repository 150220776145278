.all-4em tr td {
  font-size: 0.4em;
}
.bold {
  font-weight: bold;
}
.bold kurziv {
  font-style: italic;
}
.bold.italic {
  font-weight: bold;
  font-style: italic;
}
.center {
  text-align: center;
}
.center-all-tr tr {
  text-align: center;
}
.doc {
  height: auto;
  width: 760px;
  float: left;
  /* border-top: 1px solid #e4e4e6; */
  margin-top: 50px;
}
.doc .art-num {
  font-size: 1em;
  font-weight: bold;
  line-height: 10.5pt;
  margin: 20px 0 20px 0;
  text-align: center;
  page-break-after: avoid;
}
.doc .art-tit {
  font-size: 11pt;
  font-weight: bold;
  line-height: 110%;
  margin: 4.25pt 0 2.15pt 0;
  text-align: center;
}
.doc .clanak {
  font-size: 1.1em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.05;
  margin: 20px 0 10px 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .clanak-- {
  font-size: 1.1em;
  font-style: italic;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.048;
  margin: 0.141em 0 0.3em 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .msonormaltable {
  font-size: 10pt;
}
.doc .msotablegrid {
  border: 1pt solid windowtext;
  font-size: 10pt;
}
.doc .n-109sred {
  font-size: 1em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .note-text {
  font-size: 0.9em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.15;
  margin: 0 0 0.2em 0;
  text-align: justify;
  text-decoration: none;
  text-indent: 0;
}
.doc .pleft > td {
  text-align: left;
}
.doc .pleft.bold-kurziv {
  text-align: left;
  font-weight: bold;
  font-style: italic;
}
.doc .remark-text {
  font-size: 0.9em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.059;
  margin: 0 0 10px 0;
  text-decoration: none;
  text-indent: 0;
}
.doc .t-6 {
  font-size: 0.7em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  margin: 0 0 0.2em 0;
  text-align: justify;
  text-decoration: none;
  text-indent: 0;
}
.doc .t-7 {
  color: #231f20;
  font-size: 0.8em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  margin: 0 0 0.2em 0;
  text-align: justify;
  text-decoration: none;
  text-indent: 0;
}
.doc .t-9-8-bez-uvlleg td {
  color: #231f20;
  font-size: 0.8em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.05;
  margin: 0 0 0.2em 0;
  text-align: justify;
  text-decoration: none;
  text-indent: 0;
}
.doc .tab-head {
  font-size: 1em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.105;
  margin: 0.14em 0 1.5em 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .tab-num {
  font-size: 10pt;
  text-align: right;
}
.doc .tab-text.bold {
  font-weight: bold;
}
.doc .tab-text.center {
  text-align: center;
}
.doc .text-0,
.doc .tab-text {
  font-size: 1em;
  margin: 0 0 15px 0;
}
.doc .text-0.center {
  text-align: center;
}
.doc .text-0.center.bold {
  text-align: center;
  font-weight: bold;
}
.doc .text-0.italic {
  font-style: italic;
}
.doc .text-0.right {
  text-align: right;
}
.doc .tit-append {
  font-size: 1.2em;
  line-height: 115%;
  margin-top: 30px;
}
.doc .tit-bearer {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 115%;
  margin: 30px 0 10px 0;
  text-align: center;
  text-transform: uppercase;
}
.doc .tit-doc {
  font-size: 1.6em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .tit-sec-1 {
  font-size: 1.4em;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.1;
  margin: 25px 0 15px 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .tit-sec-2 {
  font-size: 1.2em;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1.048;
  margin: 5px 0 20px 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc .tit-sec-2.bold {
  font-weight: bold;
}
.doc .tit-sec-2.bold.italic {
  font-weight: bold;
  font-style: italic;
}
.doc .tit-sec-2.italic {
  font-style: italic;
}
.doc .tit-sec-3 {
  font-size: 1.2em;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.6;
  margin: 20px 0 20px 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
.doc .tit-sec-3.bold {
  font-weight: bold;
}
.doc .tit-sec-3.bold.italic {
  font-weight: bold;
  font-style: italic;
}
.doc .tit-sec-3.italic {
  font-style: italic;
}
.doc .tit-tab {
  font-size: 1.2em;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.05;
  margin: 20px 0 10px 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
.doc .xl29 {
  font-size: 0.9em;
}
.doc .xl37 {
  /*background: #CFC;*/
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
  border-right: none;
  border-top: 1pt solid windowtext;
  font-size: 0.9em;
  /*text-align: center;*/
}
.doc .xl38 {
  /*background: #CFC;*/
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt solid windowtext;
  border-right: 0.5pt solid windowtext;
  border-top: 1pt solid windowtext;
  font-size: 0.9em;
  /*text-align: center;*/
}
.doc .xl39 {
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-top: 1pt solid windowtext;
  font-size: 0.9em;
}
.doc .xl40 {
  border-bottom: 0.5pt windowtext;
  border-left: 0.5pt solid windowtext;
  border-right: 0.5pt solid windowtext;
  border-top: 1pt solid windowtext;
  font-size: 0.9em;
}
.doc .xl41 {
  border-bottom: 0.5pt windowtext;
  border-left: 0.5pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-top: 1pt solid windowtext;
  font-size: 0.9em;
  /*text-align: center;*/
}
.doc .xl42 {
  font-size: 0.9em;
}
.doc :not(p.tit-sec-3) + p.tit-sec-3 {
  margin-top: 30px;
}
.doc > p.tit-doc + :not(p.tit-doc) {
  margin-top: 40px;
}
.doc a {
  text-decoration: none;
}
.doc address {
  font-size: 11pt;
  font-style: italic;
  letter-spacing: -0.1pt;
  margin: 0 0 0.0001pt 0;
  text-align: center;
}
.doc div.footnote {
  font-size: 0.9em;
  margin: 20px 0 20px 0;
  vertical-align: super;
}
.doc div.sign-text {
  font-size: 12pt;
  margin: 0 0 0 0;
  text-align: center;
}
.doc em {
  font-style: normal;
}
.doc h1 {
  font-size: 18pt;
  margin: 12pt 0 3pt 0;
  page-break-after: avoid;
  text-align: center;
}
.doc h2 {
  font-size: 16pt;
  margin: 12pt 0 3pt 0;
  page-break-after: avoid;
  text-align: center;
}
.doc h3 {
  font-size: 14pt;
  margin: 12pt 0 3pt 0;
  page-break-after: avoid;
  text-align: center;
}
.doc h4 {
  font-size: 12pt;
  font-weight: bold;
  margin: 18pt 0 0.0001pt 0;
  page-break-after: avoid;
  text-align: justify;
}
.doc h5 {
  font-size: 12pt;
  margin: 0 0 0.0001pt 144pt;
  page-break-after: avoid;
}
.doc h6 {
  font-size: 12pt;
  font-style: italic;
  font-weight: normal;
  margin: 0 0 0.0001pt 0;
  page-break-after: avoid;
  text-align: right;
}
.doc img {
  height: auto;
  max-width: 100%;
  width: auto;
}
.doc ins {
  text-decoration: none;
}
.doc li {
  display: block;
}
.doc li.sign-text {
  font-size: 12pt;
  margin: 0 0 0 0;
  text-align: center;
}
.doc li.text-0 {
  font-size: 1em;
  margin: 0 0 15px 0;
}
.doc li.tit-sec-1 {
  font-size: 12pt;
  margin: 0 0 0 0;
  text-align: center;
}
.doc p {
  line-height: normal;
  margin: 0 0 5px 0;
}
.doc p br {
  content: "";
  margin: 0 0 15px 0;
  display: block;
}
.doc p.art-tit,
doc li.art-tit,
doc div.art-tit {
  font-size: 1.1em;
  font-style: italic;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.048;
  margin: 0.141em 0 0.3em 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
}
.doc p.end-0 {
  height: 60px;
  background-color: #c8c8c8;
}
.doc p.h1,
doc li.h1,
doc div.h1 {
  font-size: 18pt;
  font-weight: bold;
  margin: 0 0 2.15pt 0;
  text-align: center;
}
.doc p.h2,
doc li.h2,
doc div.h2 {
  font-size: 18pt;
  font-weight: bold;
  margin: 5pt 0 5pt 0;
  page-break-after: avoid;
}
.doc p.h3,
doc li.h3,
doc div.h3 {
  font-size: 14pt;
  font-weight: bold;
  margin: 5pt 0 5pt 0;
  page-break-after: avoid;
}
.doc p.h5,
doc li.h5,
doc div.h5 {
  font-size: 10pt;
  font-weight: bold;
  margin: 5pt 0 5pt 0;
  page-break-after: avoid;
}
.doc p.h6,
doc li.h6,
doc div.h6 {
  font-size: 8pt;
  font-weight: bold;
  margin: 5pt 0 5pt 0;
  page-break-after: avoid;
}
.doc p.n-50-0 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 60px;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  background-color: WhiteSmoke;
}
.doc p.n-50-1 {
  font-size: 9.5pt;
  font-style: italic;
  text-align: center;
  margin-top: 0cm;
  background-color: WhiteSmoke;
}
.doc p.p-napomena {
  font-size: 0.8em;
  margin-left: 10px;
  text-align: justify;
  background-color: WhiteSmoke;
}
.doc p.sign-text {
  margin: 0 0 0 0;
  text-align: center;
}
.doc p.sign-text,
doc li.sign-text,
doc div.sign-text {
  font-size: 12pt;
  margin: 0 0 0.0001pt 0;
  text-align: center;
}
.doc p.tab-num,
doc li.tab-num,
doc div.tab-num {
  border: none;
  font-size: 10pt;
  margin: 0 0 0 0;
  padding: 0;
  text-align: right;
}
.doc p.text-0 {
  font-size: 1em;
  margin: 0 0 5px 0;
}
.doc p.text-0.bold {
  text-weight: bold;
}
.doc p.tit-doc + p.tit-doc {
  margin-top: 0;
  margin-bottom: 0;
}
.doc p.tit-sec-1 + p.tit-sec-1 {
  margin-bottom: 0;
  margin-top: 0;
}
.doc p.tit-sec-1 + p:not(p.tit-sec-1) {
  margin-top: 30px;
}
.doc p.tit-sec-2 + p.tit-sec-2 {
  margin-bottom: 0;
  margin-top: 0;
}
.doc p.tit-sec-2 + p:not(p.tit-sec-2) {
  margin-top: 30px;
}
.doc p.tit-sec-2.bold + p.tit-sec-2:not(p.tit-sec-2.bold) {
  margin-top: 30px;
}
.doc p.tit-sec-2.bold.italic + p:not(p.tit-sec-2.bold.italic) {
  margin-top: 30px;
}
.doc p.tit-sec-2.italic:not(p.tit-sec-2.bold) + p.tit-sec-2:not(p.tit-saec-2.bold):not(p.tit-sec-2.italic) {
  margin-top: 30px;
}
.doc p.tit-sec-2:not(p.tit-sec-2.bold) + p.tit-sec-2.bold {
  margin-top: 30px;
}
.doc p.tit-sec-3 + p.tit-sec-3 {
  margin-bottom: 0;
  margin-top: 0;
}
.doc p:not(p.tit-doc) + p.tit-doc {
  margin-bottom: 0px;
  margin-top: 40px;
}
.doc p:not(p.tit-sec-1) + p.tit-sec-1 {
  margin-bottom: 0;
  margin-top: 30px;
}
.doc p:not(p.tit-sec-2) + p.tit-sec-2 {
  margin-top: 30px;
  margin-bottom: 0;
}
.doc p:not(p.tit-sec-2.bold.italic) + p.tit-sec-2.bold.italic {
  margin-bottom: 0;
  margin-top: 30px;
}
.doc pre {
  white-space: pre-wrap;
}
.doc section1 {
  margin: 70.85pt 70.85pt 70.85pt 70.85pt;
}
.doc strong {
  font-weight: normal;
}
.doc table {
  display: table;
}
.doc table tr {
  font-size: 10pt;
}
.doc table tr td {
  padding: 0.2em 0.2em 0.2em 0.2em;
}
.doc table.c-all td {
  border-style: solid;
  border-width: 1px;
}
.doc table.c-b td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.doc table.c-l td {
  border-left-style: solid;
  border-left-width: 1px;
}
.doc table.c-nall td {
  border-style: none;
}
.doc table.c-nb td {
  border-bottom-style: none;
}
.doc table.c-nl td {
  border-left-style: none;
}
.doc table.c-nr td {
  border-right-style: none;
}
.doc table.c-nt td {
  border-top-style: none;
}
.doc table.c-r td {
  border-right-style: solid;
  border-right-width: 1px;
}
.doc table.c-t td {
  border-top-style: solid;
  border-top-width: 1px;
}
.doc table.pcenter > td {
  text-align: center;
}
.doc table.pleft td {
  text-align: left;
}
.doc table.pright > td {
  text-align: right;
}
.doc td {
  border: none;
  color: windowtext;
  font-size: 10pt;
  font-style: normal;
  /*font-weight: 400;*/
  padding: 1px 1px 1px 1px;
  text-align: inherit;
  text-decoration: none;
  vertical-align: top;
}
.doc td .gl-c,
.doc tr > td.gl-c,
.doc table td.gl-c {
  font-size: 0.7em;
  font-weight: bold;
}
.doc td .italic {
  font-size: 0.7em;
  font-style: italic;
}
.doc td .italic,
.doc tr > td.italic,
.doc table td.italic {
  font-size: 0.7em;
  font-style: italic;
}
.doc td .tekst-u-tablici {
  font-size: 0.9em;
}
.doc td .tekst-u-tablici,
.doc tr > td.tekst-u-tablici,
.doc table td.tekst-u-tablici {
  font-size: 0.9em;
}
.doc td .x0101d,
.doc tr > td.x0101d,
.doc table td.x0101d {
  font-size: 0.7em;
  font-weight: bold;
}
.doc td .x0101l,
.doc tr > td.x0101l,
.doc table td.x0101l {
  font-size: 0.7em;
  font-weight: bold;
}
.doc td .x01d,
.doc tr > td.x01d,
.doc table td.x01d {
  font-size: 0.7em;
  font-weight: bold;
}
.doc td .x01l,
.doc tr > td.x01l,
.doc table td.x01l {
  font-size: 0.7em;
  font-weight: bold;
}
.doc td .x100d,
.doc tr > td.x100d,
.doc table td.x100d {
  font-size: 0.7em;
}
.doc td .x100l,
.doc tr > td.x100l,
.doc table td.x100l {
  font-size: 0.7em;
}
.doc td .x10d,
.doc tr > td.x10d,
.doc table td.x10d {
  font-size: 0.7em;
}
.doc td .x10l,
.doc tr > td.x10l,
.doc table td.x10l {
  font-size: 0.7em;
}
.doc td,
.doc th {
  border-style: solid;
  border-width: 1px;
}
.doc td.c-all {
  border-style: solid;
  border-width: 1px;
}
.doc td.c-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.doc td.c-l {
  border-left-style: solid;
  border-left-width: 1px;
}
.doc td.c-nall {
  border-style: none;
}
.doc td.c-nb {
  border-bottom-style: none;
}
.doc td.c-nl {
  border-left-style: none;
}
.doc td.c-nr {
  border-right-style: none;
}
.doc td.c-nt {
  border-top-style: none;
}
.doc td.c-r {
  border-right-style: solid;
  border-right-width: 1px;
}
.doc td.c-t {
  border-top-style: solid;
  border-top-width: 1px;
}
.doc td.kurziv {
  font-style: italic;
}
.doc td.pcenter {
  text-align: center;
}
.doc td.pright {
  text-align: right;
}
.doc td.rotate90 {
  transform: rotate(90deg);
}
.doc td.rotate90 p {
  transform: rotate(90deg);
}
.doc td.t-10-9-fett-lijevo {
  font-style: italic;
}
.doc text-0.bold.italic {
  text-weight: bold;
  font-style: italic;
}
.doc tr.c-all td {
  border-style: solid;
  border-width: 1px;
}
.doc tr.c-b td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.doc tr.c-l td {
  border-left-style: solid;
  border-left-width: 1px;
}
.doc tr.c-nall td {
  border-style: none;
}
.doc tr.c-nb td {
  border-bottom-style: none;
}
.doc tr.c-nl td {
  border-left-style: none;
}
.doc tr.c-nr td {
  border-right-style: none;
}
.doc tr.c-nt td {
  border-top-style: none;
}
.doc tr.c-r td {
  border-right-style: solid;
  border-right-width: 1px;
}
.doc tr.c-t td {
  border-top-style: solid;
  border-top-width: 1px;
}
.doc tr.pcenter > td {
  text-align: center;
}
.doc tr.t-10-9-fett-lijevo td {
  color: #231f20;
  font-size: 1em;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1.05;
  margin: 0 0 0.2em 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
.italic {
  font-style: italic;
}
.kurziv {
  font-style: italic;
}
.left {
  text-align: left;
}
.pcenter {
  text-align: center;
}
.pcenter.bold {
  font-weight: bold;
  text-align: center;
}
.pcenter.bold-kurziv {
  font-weight: bold;
  font-style: italic;
  text-align: center;
}
.pcenter.fus {
  font-size: 0.7em;
  text-align: center;
}
.pcenter.kurziv {
  font-style: italic;
  text-align: center;
}
.pleft {
  text-align: left;
}
.pleft.bold {
  text-align: left;
  font-weight: bold;
}
.pleft.fus {
  font-size: 0.7em;
  text-align: left;
}
.pleft.kurziv,
.doc tr.pleft.kurziv,
.doc .pleft.kurziv > td {
  text-align: left;
  font-style: italic;
}
.pright.bold {
  page: Section14;
}
.pright.bold-kurziv {
  page: Section16;
}
.pright.kurziv {
  page: Section15;
}
.right {
  text-align: right;
}
.sup {
  vertical-align: super;
  font-size: smaller;
}
.tablica {
  border: 1px solid #666666;
  border-spacing: 0;
  padding: 3px;
}
.tab-text > td {
  font-size: 0.7em;
  margin: 0cm;
}
body {
  font-family: "Roboto";
}
div.doc {
  left: 0;
  width: 100%;
}
table {
  border: 1px solid #666666;
  margin: 20px 0 20px 0;
  padding: 0;
  border-collapse: collapse;
}
table,
tr,
td {
  border: 1px solid #666666;
}
td {
  border: 1px solid #666666;
  border-spacing: 0;
  padding: 3px;
}
td.tab-text.right {
  text-align: right;
}
tr {
  margin: 0;
  padding: 0;
}
.art-num,
.tit-sec-1,
.tit-sec-2,
.tit-sec-3 {
  page-break-after: avoid !important;
}
@media print {
  .art-num,
  .art-tit,
  .tit-sec-1,
  .tit-sec-2,
  .tit-sec-3 {
    page-break-after: avoid;
  }
}
img {
  height: auto;
  max-width: 100% !important;
}
@media only screen and (max-width: 600px) {
  .doc img {
    height: auto;
    max-width: 100%!important;
  }
}
