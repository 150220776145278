@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #696969 !important;
}

.ant-collapse-content-box {
  padding: 0px 0px 0px 0px !important;
}

.odabrani-row {
  margin-left: 12.5%;
  max-width: 75%;
}
.odabrani {
  border-width: 2px !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  font-size: 16px !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #696969 !important;
  border-style: solid !important;
  border-radius: 7px !important;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  box-shadow: 0px 0px 7px rgba(66, 66, 66, 0.42) !important;
  text-shadow: -50px 0px 0px rgb(66 66 66 / 0%) !important;
  padding: 0px !important;
}
.odabrani-collapse {
  margin: 0px 0px 0px 0px !important;
  border-radius: 5px !important;
  border-width: 0px !important;
  padding: 0px !important;
  background: white !important;
  color: #696969 !important;
}
.odabrani-header {
  margin: 0px 0px 0px 0px !important;
  border-width: 0px;
  padding: 0px !important;
  border-radius: 5px !important;
  background: white !important;
  color: #696969 !important;
}
.list-group-item {
  color: #696969 !important;
}
.odabrani-list-item {
  width:100%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: lightgray;
  height:3em;
  margin-bottom: 0px;
  line-height: 3em;
}
::selection {
  color: #000 !important;
  background: #3fe2f259 !important;
}
.App {
  text-align: center;
}
.kockice td {
  width: 300px;
}
.small-fonts {
  font-size: 10px;
  color: wheat;
  background: #4c4c4c;
  padding-left: 5px;
  border-radius: 2px;
}
.shape {
  margin: 10px;
  cursor: pointer;
  display: inline-block;
}
.square {
  margin: 10px;
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #ff9000;
}
.square:hover {
  transform: rotate(400deg);
  transition: all 0.1s ease-in-out 0s;
}
.circle {
  margin: 10px;
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #3776ff;
  border-radius: 50%;
  transition: all 1s ease-in-out 0s;
}
.circle:hover {
  background: #37ffac;
  transition: all 0.1s ease-in-out 0s;
}
.mymodal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.589);
  top: 0;
  left: 0 ;
  width: 100%;
  height: 100%;
  z-index: 100000;
}
.odabrani {
  margin-top: 50px;
}
.odabrani a {
  color: #8d8d8d !important;
}
.odabrani a:hover {
  font-weight: bold;
}
.triangle {
  margin: 10px;
  cursor: pointer;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #ff00ff;
}
.triangle:hover {
  transform: rotate(20deg);
  transition: all 0.1s ease-in-out 0s;
}
.search-tooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted #666;
  text-align: left;
  background: #ff9000;
  width: 20;
  color: #4c4c4c;
  font-size: 21px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  /* margin-left: 10px; */
  margin: 5px;
  /* margin-left: 10px; */
}
.search-tooltip h3 {
  margin: 12px 0;
}
.search-tooltip .tooltip-left {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 0.5s;
  min-width: 200px;
  max-width: 400px;
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 20px;
  color: #666666;
  background-color: #ffffe0;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  display: none;
  border: 1px solid #dca;
}
.search-tooltip:hover .tooltip-left {
  display: block;
}
.search-tooltip .tooltip-left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.search-tooltip .tooltip-left i::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #ffffe0;
  border: 1px solid #dca;
}
.tooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted #666;
  text-align: left;
  background: #f28f3f;
  width: 20;
  color: black;
  width: 20px;
  height: 20px;
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}
.tooltip h3 {
  margin: 12px 0;
}
.tooltip .tooltip-left {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 0.5s;
  min-width: 200px;
  max-width: 400px;
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 20px;
  color: #666666;
  background-color: #ffffe0;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  display: none;
  border: 1px solid #dca;
}
.tooltip:hover .tooltip-left {
  display: block;
}
.tooltip .tooltip-left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
.tooltip .tooltip-left i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #ffffe0;
  border: 1px solid #dca;
}
.top-container {
  min-height: calc(100vh - 30px);
}
.footer {
  text-align: center;
  width: 100vw;
  /* background: #f4f4f5; */
  /* margin: 50px; */
  height: 30px;
  color: #9b9b9b;
  font-weight: b;
}
.footer a {
  color: #9b9b9b !important;
}
.footer-fixed {
  position: fixed;
  background: #f9f9f9;
  background: linear-gradient(0deg, #f9f9f9 22%, #dddddd 66%, rgba(255, 255, 255, 0) 100%);
  color: #333333;
  width: 100vw;
  height: 25px;
  z-index: 99999;
  bottom: 0px;
  text-align: center;
  font-size: 11px;
  padding: 5px;
}
.login-form {
  width: 300px !important;
}
.doc {
  padding: 20px;
}
.doc p {
  margin-bottom: 10px !important;
}
.search-errors {
  padding: 10px;
  background-color: #ff000008;
  font-weight: bold;
  color: #b74646;
  border-radius: 8px;
  text-align: left;
  list-style: inside;
}
.cookie-consent {
  position: fixed;
  top: calc(100vh - 200px);
  height: 200px;
  left: 0;
  width: 100vw;
  z-index: 10000;
  background-color: #3a3a3af0;
  color: white;
  padding-top: 50px;
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media print {
  html,
  body {
    display: none;
    /* hide whole page */
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
::-webkit-input-placeholder {
  color: #cccccc;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  color: #cccccc;
  opacity: 1;
  /* Firefox */
}
::-ms-input-placeholder {
  color: #cccccc;
  opacity: 1;
  /* Firefox */
}
::placeholder {
  color: #cccccc;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.css-input {
  padding: px !important;
  font-size: 16px !important;
  border-width: 2px !important;
  border-color: #f8d33c !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-style: solid !important;
  border-radius: 7px !important;
  box-shadow: 0px 0px 7px rgba(66, 66, 66, 0.42) !important;
  text-shadow: -50px 0px 0px rgba(66, 66, 66, 0) !important;
}
.search-input {
  font-size: 25px !important;
}
.search {
  display: flex;
}
.searchTerm {
  width: 100%;
  border: none;
  border-right: none;
  padding: 10px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #696969;
  height: 40px;
  font-weight: bold;
}
.searchButton {
  width: 60px;
  height: 40px;
  border: none;
  background: #f8d33c;
  text-align: center;
  color: #6b6b6b;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 25px;
  outline: none;
  background: none;
  font-weight: bold;
}
/*Resize the wrap to see the search bar change!*/
.search-wrap {
  padding: 1px !important;
  font-size: 16px !important;
  border-width: 2px !important;
  border-color: #9f9f9f !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-style: solid !important;
  border-radius: 7px !important;
  box-shadow: 0px 0px 7px rgba(66, 66, 66, 0.42) !important;
  text-shadow: -50px 0px 0px rgba(66, 66, 66, 0) !important;
  display: inline-block !important;
  width: 100%;
}
.opis {
  background: rgba(165, 252, 220, 0.23);
  background: rgba(255, 237, 214, 0.43);
  padding: 40px;
  color: #00000099;
  font-family: fangsong;
  font-size: 25px;
  margin-bottom: 35px;
  border-radius: 1px;
}

.pretraga-naslov {
  font-family: fangsong;
  font-size: 25px;
}

.pretraga-header {
  font-size: 0.8em;
  border-radius: 3px;
  border-bottom: 1px solid #d6d6d6;
}
.css-input:focus {
  outline: none !important;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #0000004a !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f18e3f !important;
  border-color: #00000026 !important;
}
.anticon-spin {
  color: black !important;
  border-width: 3px !important;
}
.ant-radio-inner:after {
  background-color: #748487 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ababab !important;
}
.ant-menu {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum", "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(47, 47, 47, 0.85) !important;
  font-size: 17px;
  font-weight: bold;
  /* line-height: 32; */
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu a {
  color: rgba(47, 47, 47, 0.85) !important;
}
.ant-menu a:hover {
  color: #3d90ff !important;
}
.ant-menu-sub.ant-menu-inline {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  padding: 0;
  padding-left: 20px !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
}
.upute {
  background-color: #4e4e4e !important;
  /* width: 40px; */
  font-size: 0.7em;
  color: #ffffff !important;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: inline-block !important;
  position: absolute !important;
  margin-left: 2px;
  cursor: pointer;
  height: 50px;
  height: 46px !important;
  border-radius: 4px !important;
  /* border-color: black!important; */
  font-size: 9px !important;
}
.link {
  cursor: pointer;
  display: inline!important;
}
.link-icon {
  height: 32px !important;
  width: 32px !important;
  margin: 5px;
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.menu-wrapper {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
}
.main-menu {
  border: 1px solid #e8e8e8;
  width: 250px;
  margin-top: 60px;
  right: 0px;
  float: right;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout-sub-header-background {
  background: #fff;
}
.site-layout-background {
  background: #fff;
}
.ant-layout-header {
  background: #fff !important;
  height: auto !important;
  padding: 10px 10px 10px 10px !important;
  line-height: 30px !important;
}
.App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.xcol {
  /* background-color: red; */
  margin: 2px;
}
html {
  height: 100%;
}
.propis-naslov-h1 {
  font-weight: bold;
  font-size: 1em;
}
body {
  font-size: 15px !important;
  background: #f5f5f5 !important;
  font-family: Roboto !important;
  overflow: scroll;
  /* Show scrollbars */
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #464646 !important;
  overflow-x: hidden !important;
}
.bckgpic {
  position: fixed !important;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  block-size: -webkit-fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.bounding-box {
  background-image: url(../images/bckg.jpg);
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: antiquewhite;
  opacity: 0.2;
}
body,
h1,
h2,
h3 {
  color: #464646 !important;
}
.spinner-fixed {
  position: fixed;
  top: 60px;
  z-index: 10105;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: #fdfdfdad;
  left: 0px;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
a {
  color: #000000 !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.btn-mobile {
  margin-top: 10px;
  margin-right: 3px;
  font-size: 10px!important;
  /* border-color: #f7f7f7; */
  border: 1px solid #dedede;
  border-radius: 3px;
  background-color: #e7e7e7;
}
.navbutton {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  padding: 6px 23px;
  text-decoration: none;
  margin-right: 5px;
  font-weight: bold;
}
.user-menu-link {
  background: transparent;
  margin: 10px;
  margin-top: 16px !important;
  /* padding: 20px; */
  /* height: 200px; */
  display: inline-block;
}
.logoimg {
  margin: auto;
}
.navlink-menu-title {
  font-weight: bold;
}
.ant-divider-horizontal.ant-divider-with-text {
  border-bottom: 1px solid #d5d5d5 !important;
}
.ant-divider-horizontal.ant-divider-with-text:after,
.ant-divider-horizontal.ant-divider-with-text:before {
  border-top: 0 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #fafafa00 !important;
}
.ant-tabs-tab:hover {
  color: #000000 !important;
}
.ant-tabs-tab:focus {
  color: #000000 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #000000 !important;
  background: rgba(255, 255, 255, 0.61) !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #000000 !important;
}
.spinner {
  color: #cccccc;
}
tbody a {
  color: #ff9000 !important;
}
.table-red {
  color: red !important;
}
.table-blue {
  color: #1890ff !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #a8a8a8 !important;
}
.ant-input-affix-wrapper::focus {
  border-color: #a8a8a8 !important;
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper-focused {
  border-color: gray !important;
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-picker {
  font-size: 1em !important;
  font-weight: bold;
  border-radius: 5px !important;
  color: #5a5a5a;
  height: 36px !important;
}
.ant-picker-input > input {
  font-weight: bold;
  color: #5a5a5a !important;
  margin: auto;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.kartica {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s;
  text-align: justify;
  background: #ffffff94 !important;
  padding: 20px !important;
  border-radius: 21px !important;
  border: 1px solid #dbdbdb !important;
  display: inline-block !important;
  width: 100% !important;
  max-width: 85vw;
}
.noborder {
  border: none !important;
}
.ant-table {
  background: #ffffff4f !important;
}
table,
tr,
td {
  border: none !important;
}
.ant-form {
  text-align: justify;
  background: #ffffff94 !important;
  padding: 20px !important;
  border-radius: 21px !important;
  border: 1px solid #dbdbdb !important;
  display: inline-block !important;
  width: 100% !important;
  max-width: 85vw;
}
.ant-collapse-borderless {
  background: #ffffff42 !important;
  border-radius: 21px !important;
  border: 1px solid #dbdbdb !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 0 !important;
}
.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  font-size: 0.8em;
  padding-left: 10px;
}
.ant-input {
  border-radius: 5px !important;
  padding-left: 10px !important;
  font-size: 1em !important;
  font-weight: bold !important;
  color: #5a5a5a !important;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  border-radius: 5px !important;
}
.ant-select-single .ant-select-selector {
  border-radius: 5px !important;
}
.ant-select-selection-item {
  font-size: 1em;
  font-weight: bold;
  color: #5a5a5a !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px !important;
}
.ant-divider-horizontal.ant-divider-with-text {
  color: #bdbdbd !important;
}
label {
  color: #848484 !important;
}
.navlikn-title {
  padding: 10px;
  font-weight: bold;
  color: aliceblue !important;
}
.title {
  margin-top: 100px;
  font-size: 50px;
  color: #666666;
  font-family: serif;
  font-family: initial;
  letter-spacing: 10px !important;
}
.title:after {
  content: "PROČIŠĆENI TEKSTOVI ZAKONA";
  font-size: 14px;
  display: block;
  margin-top: -21px;
  letter-spacing: 2px;
}
.page-title {
  /* margin-top: 100px; */
  text-align: center;
}
.lista-zakona {
  /* margin-top: 100px;; */
  font-weight: bold;
}
.lista-zakona-popis {
  height: calc(100vh - 350px) !important;
  overflow-y: scroll;
}
.user-link {
  background: #737373;
  border: 1px solid;
}
.ant-collapse-borderless {
  background-color: #f0f0f09e !important;
  border: 0;
  border-bottom: none !important;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
  color: #949494;
  font-weight: normal;
  font-size: 15px;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid #505050;
  transform: translateY(50%);
  content: "";
}
.logo {
  font-weight: bold !important;
  margin: auto;
}
.wspaylogo {
  max-width: 70vw;
}
.highlight {
  color: white;
  background-color: #4e4d4c !important;
  font-weight: bold;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
}
.search-box-filter {
  position: fixed !important;
  width: 400px !important;
  left: calc(100vw - 450px) !important;
  top: calc(100vh - 60px) !important;
  display: inline-block;
}
.p-match {
  background-color: #8af1ffb0;
}
.border-bottom {
  border-bottom: 2px solid #d6d6d6;
}
.zakon-header {
  font-size: 0.9em;
  color: #a0a0a0;
}
.zakon-item {
  /* margin: 5px; */
  /* border-radius: 2px; */
  cursor: pointer;
  /* border-bottom: 1px solid #e4e4e4; */
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s;
}
.grupa:hover {
  border-left: 10px solid #ff9914;
  animation-name: flash_border;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  -webkit-animation-name: flash_border;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: flash_border;
  -moz-animation-duration: 0.5s;
  -moz-animation-timing-function: linear;
  margin-left: -10px;
}
@keyframes flash_border {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: #ff9914;
  }
}
@-webkit-keyframes flash_border {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: #ff9914;
  }
}
.gradient-opacity {
  height: 200px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fbfbfb);
}
.zakon-item:hover {
  color: #f1a81f;
}
.header-naslov {
  display: block;
  flex: 0 0 100%;
  font-size: 24px;
  font-weight: bold;
  color: #616161;
  margin-bottom: 20px;
}
.header-naslov-propisi {
  font-size: 24px;
  font-weight: bold;
  color: #616161;
}
.nn-link {
  color: #f98745;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  display: inline-flex;
  border-radius: 5px!important;
  background: #ffffff8c !important;
}
.temeljni-zakon {
  /* margin: 5px; */
  /* border-radius: 2px; */
  cursor: pointer;
  /* border-bottom: 1px solid #e4e4e4; */
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s;
  font-weight: bold;
}
.temeljni-zakon-link {
  background-color: #f1f1f1 !important;
}
.temeljni-zakon:hover {
  color: #f1a81f;
}
.napomene {
  color: red;
}
.cijena {
  padding: 30px;
  font-size: 2em;
  font-weight: bold;
}
.izmjena {
  /* margin: 5px; */
  /* border-radius: 2px; */
  cursor: pointer;
  /* border-bottom: 1px solid #e4e4e4; */
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s;
  height: 100% !important;
  font-weight: normal;
}
.izmjena-marked {
  /* margin: 5px; */
  /* border-radius: 2px; */
  cursor: pointer;
  /* border-bottom: 1px solid #e4e4e4; */
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s;
  height: 100% !important;
  font-weight: normal;
}
.izmjena:hover {
  color: #f1a81f;
}
.izmjena-marked:hover {
  color: #f1a81f;
}
.propis-izmjena {
  cursor: pointer;
  /* background-color: white; */
  padding: 10px;
  color: burlywood;
}
.propis-izmjena::before {
  content: "";
  position: absolute;
}
.propis-izmjena:hover {
  color: beige;
}
.propismoj {
  border: 1px solid;
  margin: 2px;
  padding: 10px;
  min-height: 70px !important;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #bbbbbb;
}
.ant-modal-body {
  max-height: 80vh!important;
  overflow: auto!important;
}
.ant-input:focus {
  border-color: #747474;
  color: #3d3d3d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}
.ant-dropdown-menu {
  width: inherit;
}
/* width */
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #838383;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.absolute-center {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.pocetna-slika {
  text-align: center;
}
.space {
  margin: 20px;
}
.header {
  background: #000000;
  color: #f1f1f1;
}
.header-mobile {
  background-color: black;
}
.header-mobile-icon {
  font-size: 25px;
  color: whitesmoke !important;
  padding: 10px;
  height: 100px;
  display: inline-block;
  height: 40px;
  margin-right: 10px;
}
.ant-btn-link,
.ant-dropdown-link {
  color: #e9e9e9 !important;
  font-weight: bold;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #f2903fb9 !important;
  background: #fff;
  border-color: #f2903f57 !important;
}
.search-button {
  box-shadow: inset 0 1px 0 0 #fff6af !important;
  background: linear-gradient(180deg, #ffb376 5%, #d78746) !important;
  background-color: #f28f3f !important;
  border-radius: 6px !important;
  border: 1px solid #fa2 !important;
  display: inline-block !important;
  cursor: pointer !important;
  color: #333 !important;
  font-family: Arial !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  /* text-shadow: 0 1px 0 #fe6!important */
}
.search-button:hover {
  color: #333 !important;
}
.search-button:focus {
  color: #333 !important;
}
.search-button:active {
  position: relative;
  top: 1px;
}
.propis-tekst {
  background-color: white;
  background: transparent !important;
  border: 1px solid #efefef;
  border: none;
  border-radius: 20px;
  margin-top: 60px;
  max-width: 2000px;
  display: inline-block;
}
.propis-naslov {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
}

.propis-podnaslov {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: #f18e3e;
}
.fadein {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
/* Opera < 12.1 */
.propis-button {
  font-size: 1em;
  border-radius: 7px !important;
  margin-right: 5px !important;
  height: 34px !important;
  display: inline-block;
  font-size: 0.9em !important;
}
.divider {
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
}
p {
  margin-bottom: 10px !important;
}
.search-box {
  margin-top: 100px;
  margin-bottom: 5px;
  text-align: center;
}
.search-box-button {
  font-size: 1em;
  border-radius: 7px !important;
  height: 32px !important;
}
.list-button {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  background-color: #4a4a4a;
  cursor: pointer;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  outline: none;
  font-size: 10px;
  transition: 0.3s;
  color: beige;
  margin-right: 10px;
}
.list-button:hover {
  background-color: #ffe961 !important;
  color: black !important;
}
.list-row-mobile {
  /* border: 1px solid #c1c1c1 !important; */
  border-radius: 0px !important;
  padding-bottom: 10px !important;
  margin-top: 10px;
  border-bottom: 1px solid #b5b5b5;
}
.propis-message {
  color: green;
  /* margin-top: -31px!important; */
  /* width: 267px; */
  display: inline-table;
  padding-right: 10px;
  padding-left: 10px;
}
.poslano-message {
  color: green;
  /* margin-top: -31px!important; */
  /* width: 267px; */
  display: inline-table;
  padding-right: 10px;
  padding-left: 10px;
}
.kupljeno-message {
  color: green;
  /* margin-top: -31px!important; */
  /* width: 267px; */
  display: inline-table;
  padding-right: 10px;
  padding-left: 10px;
}
.poslano-message-after {
  margin-left: 18px;
  font-size: 10px;
  margin-top: -5px;
  position: absolute;
  color: #9dcd9d;
}
.poveznica {
  color: red;
  font-weight: bold;
  cursor: pointer;
}
.unutarnja-poveznica {
  color: red;
  font-weight: bold;
  cursor: pointer;
}
.vanjska-poveznica {
  color: red;
  font-weight: bold;
  cursor: pointer;
}
.led-green {
  display: inline-block;
  position: absolute;
  /* margin: 0 auto; */
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;
  margin-top: 5px;
  margin-right: 40px;
  margin-left: -40px;
  cursor: pointer;
}
.ant-popover-inner {
  padding: 40px!important;
  background-color: #f7f7f7 !important;
  border-radius: 10px!important;
}
@media only screen and (max-width: 600px) {
  .cookie-consent {
    position: fixed;
    top: calc(100vh - 300px);
    height: 300px;
  }
  .top-container {
    min-height: calc(100vh - 60px);
  }
  .header-mobile-icon {
    margin-top: 10px;
  }
  .list-button {
    border-radius: 5px;
    width: 50px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    margin: 10px 10px;
    outline: none;
    font-size: 8px;
    transition: 0.3s;
  }
  .propis-tekst {
    background-color: #f1f1f1;
    padding: 10px;
    margin-top: 40px;
  }
  .search-box-filter {
    position: fixed !important;
    width: 90vw !important;
    left: 5vw !important;
    top: calc(100vh - 60px) !important;
    z-index: 1000;
    display: inline-block;
    text-align: right;
  }
  .title {
    font-size: 40px;
  }
  .title:after {
    content: "PROČIŠĆENI TEKSTOVI ZAKONA";
    font-size: 9px;
    display: block;
    margin-top: -10px;
    letter-spacing: 2px;
  }
  .sl-content {
    font-size: 0.7em;
  }
  .propis-naslov {
    font-size: 1em;
  }
  
  .propis-button {
    font-size: 0.8em !important;
    width: 100%;
    margin-top: 5px !important;
    margin-right: 0px;
  }
  .zakon-item {
    font-size: 0.8em;
    transition: 0.3s;
  }
  .zakon-item:hover {
    background: #fbfbfb;
  }
  .izmjena {
    font-size: 1em !important;
    transition: 0.3s;
  }
  .izmjena-marked {
    font-size: 1em !important;
    transition: 0.3s;
  }
  .search-box {
    margin-top: 25px;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    width: 50vw;
    padding: 20px;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    padding: 20px;
  }
  .navlink-title {
    font-size: 1.2em;
  }
  .lista-zakona-popis {
    height: calc(100vh - 200px) !important;
    overflow-y: scroll;
  }
}
.ant-tooltip {
  z-index: 10000!important;
  font-family: Roboto!important;
  font-size: 12px !important;
  font-weight: regular !important;
  border-radius: 10px !important;
}
.ant-tooltip-inner {
  border-radius: 10px !important;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: #f5f5f5 !important;
  cursor: default!important;
  border: 1px solid #ffd093 !important;
}
.ant-spin-dot-item {
  background-color: #2a2a2a !important;
}
.ant-spin {
  color: #5c5c5c !important;
}
